import { updateDealershipUserInfo } from "../services/dealership-user";
import { CCFProfileUpdateQuizIndexMapping, ANRProfileUpdateQuizIndexMapping, ANRQuizIndexMapping, CCFQuizIndexMappingV1, CCFQuizIndexMappingV0 } from "../data/dealershipQuiz/quizIndexMapping";
import { updateUserProfile } from "../services/dealership-user";

export async function submitTestDriveApproval(userId)  {

    const testDriveApprovalResponses = this.responses.call(this);

    console.log(testDriveApprovalResponses, "testDriveApprovalResponses");

    const response = await updateDealershipUserInfo(
      userId,
      testDriveApprovalResponses
    );
    console.log(response, "response");
};

export async function submitUserUpdatedProfile(userId, dealershipId)  {

  const updatedProfileResponses = this.responses.call(this);

  console.log(updatedProfileResponses, "updatedProfileResponses");

  const response = await updateUserProfile(
    userId,
    updatedProfileResponses,
    dealershipId,
    CCFProfileUpdateQuizIndexMapping,
    ANRProfileUpdateQuizIndexMapping,
    CCFQuizIndexMappingV0,
    CCFQuizIndexMappingV1,
    ANRQuizIndexMapping
  );
  console.log(response, "response");
};