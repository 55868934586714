import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

/* Importing pages for the dealership product*/
import NotFound from "./pages/NotFound/NotFound";
import Quiz from "./pages/Quiz/Quiz";
import NoMatches from "./components/NoMatches/NoMatches";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import DealershipHome from "./pages/DealershipHome/DealershipHome";



function App() {
  

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:dealershipId" element={<DealershipHome />} />
        <Route path="/:dealershipId/matchmaker-quiz/" element={<Quiz />} />
        <Route path="/:dealershipId/matches" element={<Gallery />} />
        <Route path="/:dealershipId/matches/zero" element={<NoMatches />} />
        <Route path="/:dealershipId/testdrive" element={<Quiz />} />
        <Route path="/:dealershipId/update-profile" element={<Quiz />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
