import { createContext, useContext, useState } from "react";


const DealershipUserContext = createContext({});


export const DealershipUserProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [userCarMatches, setUserCarMatches] = useState({});

    return <DealershipUserContext.Provider
     value={{
     user,
     setUser,
     userCarMatches,
     setUserCarMatches
    }}
    >
        {children}
    </DealershipUserContext.Provider>
}

export const useDealershipUserContext = () => {
    return useContext(DealershipUserContext)
}