import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { DealershipProvider } from "./contexts/dealershipContext";
import { DealershipUserProvider } from './contexts/dealershipUserContext';

const root = ReactDOM.createRoot(document.getElementById("root"));

//supressing logs
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
          <DealershipProvider>
            <DealershipUserProvider>
              <App />
            </DealershipUserProvider>
          </DealershipProvider>
    </BrowserRouter>
  </React.StrictMode>
);
