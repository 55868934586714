import cloneDeep from 'lodash.clonedeep';
import { CCFQuizQuestions } from './CCFQuizQuestions';
import { ANRQuizQuestions } from './ANRQuizQuestions';
import { ANRUpdateQuizQuestions} from './ANRUpdateProfileQuiz';
import { CCFUpdateQuizQuestions } from './CCFUpdateProfileQuiz';
import { ANR_ID, CCF_ID } from '../../constants/dealershipIds';
import { CCFQuizIndexMappingV0, CCFQuizIndexMappingV1, ANRQuizIndexMapping, CCFProfileUpdateQuizIndexMapping, ANRProfileUpdateQuizIndexMapping, specialQuizIndexes } from './quizIndexMapping';

function preFillUpdateProfileQuestions(dealershipId, quizQuestions, user, userCarMatches) {
   
   if(!user?.profile) return [];
   const newQuizQuestions = cloneDeep(quizQuestions);

   const isCCF = dealershipId === CCF_ID;
   const quizIndexMapping =  isCCF ? (user.profile.quiz?.length <= 14 ? CCFQuizIndexMappingV1 : CCFQuizIndexMappingV0) : ANRQuizIndexMapping;
   const profileUpdateQuizIndexMapping = isCCF ? CCFProfileUpdateQuizIndexMapping : ANRProfileUpdateQuizIndexMapping;
     
   Object.entries(profileUpdateQuizIndexMapping).forEach(([index, mappedIndex]) => {
     const questionInstance =  newQuizQuestions[0]?.children?.[mappedIndex];

     if(questionInstance?.prefill) {
        questionInstance.prefill.call(questionInstance, user.profile?.quiz?.[quizIndexMapping[index]], userCarMatches);
     }
   })
   return newQuizQuestions;
}


export const quizQuestionsFactory = (dealershipId, pathname, user, userCarMatches) => {
    switch(pathname) {
        case `/${CCF_ID}/matchmaker-quiz` : return cloneDeep(CCFQuizQuestions)
        case `/${ANR_ID}/matchmaker-quiz` : return cloneDeep(ANRQuizQuestions)
        case `/${CCF_ID}/testdrive` : return cloneDeep(CCFQuizQuestions)
        case `/${ANR_ID}/testdrive` : return cloneDeep(ANRQuizQuestions)
        case `/${ANR_ID}/update-profile` : return preFillUpdateProfileQuestions(dealershipId, ANRUpdateQuizQuestions, user, userCarMatches)
        case `/${CCF_ID}/update-profile` : return preFillUpdateProfileQuestions(dealershipId, CCFUpdateQuizQuestions, user, userCarMatches)
        default : return [];
    }
}

export const getQuizCount = (dealershipId, pathname) => {
    switch(pathname) {
        case `/${CCF_ID}/matchmaker-quiz` : return specialQuizIndexes[dealershipId].userDetailsQuizIndex + 1;
        case `/${ANR_ID}/matchmaker-quiz` : return specialQuizIndexes[dealershipId].userDetailsQuizIndex + 1
        case `/${CCF_ID}/testdrive` : return specialQuizIndexes[dealershipId].testDriveQuizIndex;
        case `/${ANR_ID}/testdrive` : return specialQuizIndexes[dealershipId].testDriveQuizIndex;
        case `/${ANR_ID}/update-profile` : return specialQuizIndexes[dealershipId].updateProfileViewIndex + 1;
        case `/${CCF_ID}/update-profile` : return specialQuizIndexes[dealershipId].updateProfileViewIndex + 1;
        default : return 0
    }
}

export const getQuizInitialIndex = (dealershipId, pathname) => {
    switch(pathname) {
        case `/${CCF_ID}/testdrive` : return specialQuizIndexes[dealershipId].testDriveQuizIndex;
        case `/${ANR_ID}/testdrive` : return specialQuizIndexes[dealershipId].testDriveQuizIndex;
        default : return 0
    }
}

export const getQuizViewBtnText = (pathname, count, currentQuestionViewIndex) => {
    switch(pathname) {
        case `/${CCF_ID}/matchmaker-quiz` : return count === currentQuestionViewIndex + 1 ? "Complete Check-In" : "Continue";
        case `/${ANR_ID}/matchmaker-quiz` : return count === currentQuestionViewIndex + 1 ? "Complete Check-In" : "Continue";
        case `/${CCF_ID}/testdrive` : return "Submit";
        case `/${ANR_ID}/testdrive` : return "Submit";
        case `/${ANR_ID}/update-profile` : return "Update";
        case `/${CCF_ID}/update-profile` : return "Update";
        default : return 0
    }
    
}
