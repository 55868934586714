import { CCF_ID } from "../constants/dealershipIds";
import { ANRQuizIndexMapping, CCFQuizIndexMappingV0, CCFQuizIndexMappingV1 } from "../data/dealershipQuiz/quizIndexMapping";

export const createDealershipUser = async (
  quizSelections,
  dealershipId,
  userSource,
  salesPersonId,
  salesPersonEmail
) => {

  const dealershipQuizIndexMapping = dealershipId === CCF_ID ? CCFQuizIndexMappingV1 : ANRQuizIndexMapping;

  console.log("Request Sent");
  try {
    const response = await fetch("/api/dealershipuser/createdealershipuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selected: quizSelections,
        dealershipQuizIndexMapping,
        dealershipId,
        userSource,
        salesPersonId,
        salesPersonEmail
      }),
    });

    if (response.ok) {
      return await response.json();
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const getDealershipUserInfo = async (id,email,dealershipId) => {

  console.log(dealershipId)

  try {
    const response = await fetch("/api/dealershipuser/getdealershipuserinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        openedThroughEmail : Number(email),
        CCFQuizIndexMappingV0, //backward compatibility
        CCFQuizIndexMappingV1,
        ANRQuizIndexMapping,
        dealershipId
      }),
    });

    if (response.ok) {
      const res =  await response.json();
      console.log(res, "response --");
      return res;
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const updateDealershipUserInfo = async (id, updateObj) => {


  try {
    const response = await fetch("/api/dealershipuser/updatedealershipuserinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        updateObj
      }),
    });

    if (response.ok) {
      const res =  await response.json();
      console.log(res, "response");
      return res;
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserProfile = async ( 
  userId,
  updatedProfileResponses,
  dealershipId,
  CCFProfileUpdateQuizIndexMapping,
  ANRProfileUpdateQuizIndexMapping,
  CCFQuizIndexMappingV0,
  CCFQuizIndexMappingV1,
  ANRQuizIndexMapping
) => {
  try {
    const response = await fetch("/api/dealershipuser/updateuserprofile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        updatedProfileResponses,
        dealershipId,
        CCFProfileUpdateQuizIndexMapping,
        ANRProfileUpdateQuizIndexMapping,
        CCFQuizIndexMappingV0,
        CCFQuizIndexMappingV1,
        ANRQuizIndexMapping
      }),
    });

    if (response.ok) {
      const res =  await response.json();
      console.log(res, "response");
      return res;
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
}

export const updateComparisonChartStates = async (userID, comparisonChartStates) => {
  try {
    const response = await fetch("/api/dealershipuser/updatecomparisonchartstates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID,
        comparisonChartStates,
      }),
    });

    if (response.ok) {
      console.log(response.json().message);
      return
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
}
