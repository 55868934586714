import './QITestDrive.scss';
import { useState, useEffect, useRef } from "react";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel.jsx";
import { produce } from "immer";
import { CheckboxWithCollapsibleText } from '../CheckBoxWithDisclaimer/CheckBoxWithCollapsibleText.jsx';
import { SignatureCanvas } from 'react-signature-canvas';
import {ReactComponent as Bin} from '../../assets/icons/bin.svg';

export default function QITestDrive({ dealershipId, currentQuestionViewIndex, quizQuestions, setQuizQuestions, childIndex}) {

  const getMaxDOB = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const day = (date.getDate()).toString().padStart(2,'0');

    return `${year-15}-${month}-${day}`
  }
  
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [employmentStatusError, setEmploymentStatusError] = useState("");
  const [sinNumber, setSinNumber] = useState("");
  const [sinNumberError, setSinNumberError] = useState("");
  const [experience, setExperience] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [testDriveApproval , setTestDriveApproval] = useState(false);
  const [financePreApproval , setFinancePreApproval] = useState(false);

  const employmentStatusOptions =[
    "",
    "Full-Time",
    "Part-Time",
    "Self-employed/Contractor",
    "Unemployed",
    "Student",
    "Retired",
    "Other"
  ];

  const experienceOptions = [
    "",
    "0-1 Year", "1-2 Years",
    "⁠3-4 Years",
    "5+ Years"
  ];
  
  const [signaturePenColor, setSignaturePenColor] = useState('black')

  const sigRef = useRef(null);


  const signatureTerms = `*By clicking ‘Submit’ and signing electronically, you consent to the use of your electronic signature as legally binding under applicable electronic signature laws. You also authorize Applewood Nissan Richmond to process your information for test drive scheduling and/or pre-approval purposes.`
  const financePreApprovalDisclaimer =`Portions of the above information will be submitted for the purposes of obtaining credit. The undersigned authorizes any of the Applewood Group Dealerships to make such inquiries as are necessary to obtain credit information and authorize banks, financial institutions, insurers, and credit references to release information regarding their accounts.
                                You acknowledge that, for the initial application, we may obtain two credit bureaus on each Applicant and Co-Applicant.`
  const testDriveApprovalDisclaimer = `This test drive agreement (the "Agreement”) is made by and between CUSTOMER . (the "Customer”) and APPLEWOOD NISSAN RICHMOND (the Dealer”) to set forth the terms under which the Customer may evaluate the vehicle with stock and tag identification numbers as listed above (the "Vehicle”). 
                             Customer hereby agrees to the following:
                            1.) Customer shall only drive the Vehicle from the Start Date and Time until the End Date and Time as defined above.
                            2.) Customer shall be responsible for any and all liabilities (civil, criminal or otherwise) incurred while driving the Vehicle.
                            3.) Customer shall not use the Vehicle for any purpose deemed illicit or illegal, or for any purpose which is otherwise in violation of federal, state or local laws.
                            This Agreement shall in no way imply the transfer of ownership of the Vehicle from Dealer to Customer. 
                            Failure to comply with the terms of this Agreement, including failure to return the Vehicle at or before the Test Drive End Time on the Test Drive End Date, may result in criminal and/or civil proceedings being brought against Customer.
                            I accept full responsibility while using this vehicle. | will provide insurance at or above dealer requirements or state statutes covering liability, physical damage, and bodily injury for myself or any passenger (other than the salesperson) that may arise from the use, operation, maintenance or possession of the vehicle.`


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
     setQuizQuestions(produce((quizQuestionsDraft) => {
        quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].financePreApproval = financePreApproval
     }))
  }, [financePreApproval])

  useEffect(() => {
    setQuizQuestions(produce((quizQuestionsDraft) => {
       quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].testDriveApproval = testDriveApproval
    }))
 }, [testDriveApproval])

  const validateEmploymentStatus = (employmentStatus) => {
    if(!employmentStatus && employmentStatusOptions.includes(employmentStatus)) {
      setEmploymentStatusError('Wrong Value for Employment Status');
      return false;
    } else {
      return true;
    }

  }

  const validateDateOfBirth = (dateOfBirth) => {

    const dateOfBirthRegex = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
    if (dateOfBirth && !dateOfBirthRegex.test(dateOfBirth)) {
      setDateOfBirthError("Invalid Date of Birth");
      return false;
    }
    setDateOfBirthError("");
    return true;
  };

  const validateSinNumber = (sinNumber) => {
    const sinNumberRegex = '';
    if (sinNumber && !sinNumberRegex.test(employmentStatus)) {
      setSinNumberError("Invalid SIN number");
      return false;
    }
    setSinNumberError("");
    return true;
  };

  const validateExperience = (employment) => {
    const experienceRegex = /^\S.*$/;
    if (experience && !experienceRegex.test(employment)) {
      setExperienceError("Invalid Employment Field");
      return false;
    }
    setExperienceError("");
    return true;
  };

  return (
    <>
      <CheckboxWithCollapsibleText text = "Test Drive Approval"  collapsibleTextHeader = "Test Drive Disclaimer" collapsibleText = {testDriveApprovalDisclaimer} check = {testDriveApproval} toggleCheck={setTestDriveApproval}/>
      <CheckboxWithCollapsibleText text = "Finance Pre-Approval"  collapsibleTextHeader = "Finance Pre-Approval Disclaimer" collapsibleText = {financePreApprovalDisclaimer} check={financePreApproval} toggleCheck={setFinancePreApproval}/>
      {financePreApproval && <div className="combine-fields">
        <FormFieldWithLabel
            label="Date of birth"
            type="date"
            id="dateOfBirth"
            placeholder="2002-08-20"
            value={dateOfBirth}
            max={getMaxDOB()}
            onClick={(e) => {
              console.log(e.target, e.target.showPicker)
              if(e.target.showPicker) { e.target.showPicker()} 
            }}
            onChange={(e) => {
                if (validateDateOfBirth(e.target.value)) {
                setQuizQuestions(
                    produce((quizQuestionsDraft) => {
                    quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].dateOfBirth = e.target.value;
                    })
                );
                }
                setDateOfBirth(e.target.value);
            }}
            errorMessage={dateOfBirthError}
        />
        <div className="dropdown-group">
            <label htmlFor="employment-status">Employment Status</label>
            <select
              value={quizQuestions[currentQuestionViewIndex].children[childIndex].employmentStatus}
              onChange={(e) => {
                if (validateEmploymentStatus(e.target.value)) {
                setQuizQuestions(
                    produce((quizQuestionsDraft) => {
                    quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].employmentStatus = e.target.value;
                    })
                );
                }
                setEmploymentStatus(e.target.value);
             }}
              errorMessage={employmentStatusError}
              className="employment-dropdown"
            >
              {employmentStatusOptions.map((opt, index) => {
                return <option key={index} value={opt}>{opt}</option>
              })}
            </select>
        </div>
      </div>}
      {financePreApproval && <div className="combine-fields">
        <FormFieldWithLabel
                label="SIN (coming soon)"
                type="password"
                id="sinNumber"
                placeholder=""
                value={sinNumber}
                disabled={true}
         
                onChange={(e) => {
                    if (validateSinNumber(e.target.value)) {
                    setQuizQuestions(
                        produce((quizQuestionsDraft) => {
                        quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].sinNumber = e.target.value;
                        })
                    );
                    }
                    setSinNumber(e.target.value);
                }}
                errorMessage={sinNumberError}
        />
        <div className="dropdown-group">
            <label htmlFor="experience">How long have you worked here?</label>
            <select
              value={quizQuestions[currentQuestionViewIndex].children[childIndex].experience}
              onChange={(e) => {
                if (validateExperience(e.target.value)) {
                setQuizQuestions(
                    produce((quizQuestionsDraft) => {
                    quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].experience = e.target.value;
                    })
                );
                }
                setExperience(e.target.value);
             }}
              errorMessage={experienceError}
              className="employment-dropdown"
            >
              {experienceOptions.map((opt, index) => {
                return <option key={index} value={opt}>{opt}</option>
              })}
            </select>
        </div>
      </div>
      }
      <div className='signature-area-container'>
        <div>Signature</div>
        <div className="signature-box" >
            <SignatureCanvas penColor={signaturePenColor}
                ref={sigRef}
                backgroundColor = 'white'
                velocityFilterWeight = {1}
                dotSIZE = {10}
                onEnd = {() => {
                    setQuizQuestions(produce((quizQuestionsDraft) => {
                       quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].signature = sigRef.current.toDataURL();
                    }))
                }}
                canvasProps={{width: 275, height: 150, className: 'sigCanvas' }} />
            <div className='signature-box__tools'>
                {/* <SignaturePen onClick={() => {setSignaturePenColor('black')}} style={{color : 'red'}} className='sgn-pen-black'/>
                <SignaturePen onClick={() => {setSignaturePenColor('red')}} fill = "red" className='sgn-pen-red'/>
                <SignaturePen onClick={() => {setSignaturePenColor('blue')}} fill = "blue" className='sgn-pen-blue'/>          */}
                <Bin className='sgn-clear' onClick={() => {
                    sigRef.current.clear()
                    setQuizQuestions(produce((quizQuestionsDraft) => {
                        quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].signature = '';
                     }))
                }} />
            </div>  
            
        </div>
        <div className = 'signature-terms-text' style={{marginTop :'-10px'}}>
            {signatureTerms}
         {/* <CollapsibleText textHeader = "By Signing..." text = {signatureTerms}/> */}
        </div>
      </div>
  </>
  );
}
