import './CheckBoxWithCollapsibleText.scss'
import { useState } from 'react';


export function CollapsibleText({textHeader, text}) {
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    return (
        <>
        <div
            className="collapsible-text-toggle"
            onClick={() => setShowDisclaimer(!showDisclaimer)}
        >
        <span>{textHeader}</span>
        <span className={`chevron ${showDisclaimer ? "open" : ""}`}>&#9660;</span>
        </div>

        {showDisclaimer && (
            <div className="collapsible-text-value">
            {text}
            </div>
        )}
        </>
    )
}

export function CheckboxWithCollapsibleText ({text , collapsibleTextHeader, collapsibleText, check, toggleCheck})  {
  
  return (
    <div className="checkbox-container">
      <label className="checkbox-wrapper">
        <input type="checkbox" className="hidden-checkbox" value={check} onChange={(ev) => {toggleCheck(ev.target.checked)}}/>
        <span className="custom-checkbox" />
          {text}
      </label>
      <CollapsibleText textHeader={collapsibleTextHeader} text={collapsibleText} />
    </div>
  );
};