/* eslint-disable react-hooks/exhaustive-deps */
import { produce } from "immer";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel";
import { useState, useEffect } from "react";
import { useDealershipContext } from "../../contexts/dealershipContext";
import './QIInventoryListInput.scss'
import { useFetchInventoryList } from "../../hooks/fetchInventoryList";
import { ReactComponent as X} from "../../assets/images/input-X.svg";

function QuizInputList( { filteredInventoryList, editAndFilterInputValue } ) {

  function handleMouseDownEv(input) {
    editAndFilterInputValue(input , true)
  }


  function buildUserChoiceQueryStr(year = "", make = "", model="", trim = "") {
   const data = {
    year,
    make,
    model,
    ...(trim?.length > 0 ? {trim} : {})
   }

   const queryString = new URLSearchParams(data).toString();

   console.log(queryString, "queryString");

   return queryString;

  }

  return (
    <div className="quiz-input-list-menu">
      {filteredInventoryList.map((item, index) => (
        <div key={index}>
          {item.trims && item.trims.length === 1 && 
          <div onMouseDown ={() => handleMouseDownEv(
            buildUserChoiceQueryStr(item.year, item.make, item.model, item.trims[0])
            )} className="quiz-input-list-item">
            {item.carName}
            <span className="sub-item">
                  {item.trims[0]}
            </span>
          </div>}
          {item.trims && item.trims.length > 1 && <div onMouseDown ={() => handleMouseDownEv(
            buildUserChoiceQueryStr(item.year, item.make, item.model)
            )} className="quiz-input-list-item">{item.carName}</div>}
          {item.trims && item.trims.length > 1 && (
            <div className="quiz-input-list-sublist">
              {item.trims.map((trim, subIndex) => {
                return <div key={subIndex}>
                { trim ? <div onMouseDown ={() => handleMouseDownEv(
                  buildUserChoiceQueryStr(item.year, item.make, item.model, trim)
                  )} className="quiz-input-list-item sub-item">
                  {trim}
                </div> : null}
                </div>
               })}
            </div>
          )}
        </div> 
      ))}
    </div>
  )
}

export default function QIInventoryListInput({ currentQuestionViewIndex, childIndex, quizQuestions, setQuizQuestions }) {

  const { dealershipId } = useDealershipContext();
  const [ filteredInventoryList , setFilteredInventoryList ] = useState([]);
  const [inventoryListShow, setInventoryListShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { inventoryList, isRequestPending } = useFetchInventoryList(dealershipId, filteredInventoryList, setFilteredInventoryList);
  const [lastSearchText, setLastSearchText] = useState("");
  const [userChoiceQueryStr, setUserChoiceQueryStr] = useState("");
  const [fromInventory , setFromInventory] = useState(false);

  // useEffect(() => {
  //    if(filteredInventoryList?.length === 0) setFilteredInventoryList(inventoryList);
  // },[inventoryList])

  const openDropdown = () => {
    setInventoryListShow(true);
  };

  const closeDropdown = () => {
    setInventoryListShow(false);
  };

  function decodeUserChoiceStr( str ) {
    const codedStr = new URLSearchParams(str)
    
    const year = codedStr.get("year") || "";
    const make = codedStr.get("make") || "";
    const model = codedStr.get("model") || "";
    const trim = codedStr.get("trim") || "";

    if(!year || !make || !model) return str;

    return  [year, make, model, trim].filter(Boolean).join(" ");

  }



  useEffect(() => {
    if (inputValue) {
      //console.log(inputValue , "inputValue");
      const toFilterInventoryList = lastSearchText?.length < inputValue?.length && filteredInventoryList?.length ? filteredInventoryList : inventoryList;
      //console.log(toFilterInventoryList,"an inventory list",lastSearchText?.length,  inputValue?.length, inventoryList);
      const updatedFilteredInventoryList = toFilterInventoryList.map((group, _) => {
        const updatedTrims = group.trims.filter((trim) => {
           const baseMatchStr = `${group.carName} ${trim}`
           return  ( baseMatchStr.toLowerCase().includes(inputValue.toLowerCase()) || 
             baseMatchStr.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(inputValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() 
            ))
        });
        return updatedTrims?.length > 0 ? {
          ...group,
          trims : updatedTrims
        } : null;
      }).filter(Boolean);
      //console.log(updatedFilteredInventoryList , "updated filtered inventory list");
      setFilteredInventoryList(updatedFilteredInventoryList);
      return;
    }  
    console.log(inventoryList, "inventoryList") 
    setFilteredInventoryList(inventoryList);      
  },[inputValue, inventoryList])


  useEffect(() => {
    let currentInputValue = quizQuestions[currentQuestionViewIndex].children[childIndex].inputValue;
    const fromInventory = quizQuestions[currentQuestionViewIndex].children[childIndex].inputFromInventory
    if(fromInventory) {
      setUserChoiceQueryStr(currentInputValue);
      setFromInventory(fromInventory);
    }
    currentInputValue = decodeUserChoiceStr(currentInputValue);
    setLastSearchText(currentInputValue)
    setInputValue(currentInputValue);
  }, [])


  useEffect(() => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const currentQuestionDraft = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        currentQuestionDraft.inputValue =  userChoiceQueryStr?.length > 0 ? userChoiceQueryStr : filteredInventoryList?.length === 0 ? inputValue : "";
        currentQuestionDraft.inputFromInventory = fromInventory;
    }))
  }, [filteredInventoryList])


  function filterInputValue(text, fromInventory = false) {
    //let currentInputValue = quizQuestions[currentQuestionViewIndex].children[childIndex].inputValue;
    setFromInventory(fromInventory);
    if( fromInventory ) { setUserChoiceQueryStr(text) }
    const currentInputValue = decodeUserChoiceStr(text);
    //console.log(currentInputValue , "currentInputValue");
    setLastSearchText(currentInputValue)
    setInputValue(currentInputValue);
    if (fromInventory) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }


  return (
    <div className="quiz-input-container" >
      <FormFieldWithLabel
          label= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          type= "text"
          id= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          placeholder=""
          value={inputValue}
          onChange={(e) => {
            filterInputValue(e.target.value, false);
          }}
          onFocus ={openDropdown}
          onBlur={closeDropdown}
          errorMessage={""}
        />
        {/* <div onClick={() => {
            filterInputValue('', false);
        }} className="clear_input_btn"> <X /> </div> */}
      {inventoryListShow && !isRequestPending && filteredInventoryList?.length > 0  && <QuizInputList filteredInventoryList={filteredInventoryList}  editAndFilterInputValue={filterInputValue} /> }
    </div>
  );
}
