import { BUDGET_QUESTION_ID } from "../constants/questionIds"

export function collectResponsesSeparate() {
    return this.children.map((childQuestion,_) => {
        return {
            question : this.children.length <= 1 ? this.viewText : childQuestion.text,
            selectedOptions : childQuestion.responses ? childQuestion.responses.call(childQuestion) : childQuestion.options?.filter((opt) => opt.selected).map((opt) => opt.text.replace(/^[^a-zA-Z0-9]+/, '')
        ) || [],
            ...(childQuestion.inputCondition ? {inputValue : childQuestion.inputValue} : {})
        }
    })
}

export function collectResponsesAggregated() {
    return {
        question : this.viewText,
        selectedOptions : this.children.flatMap((childQuestion,_) => {
            return childQuestion.options?.filter((opt) => opt.selected).map((opt) =>  opt.text.replace(/^[^a-zA-Z0-9]+/, '')) || []
        })
    }
}

export function updatedProfileResponses() {
    return this.children.map((childQuestion,_) => {
        console.log(childQuestion, "childQuestion value");
        return childQuestion.childQuestionId === BUDGET_QUESTION_ID && childQuestion.currSelected.length > 0 ? 
        {      question: childQuestion.text,
               selectedOptions: childQuestion.currSelected.length > 0 ? [...childQuestion.currSelected, childQuestion.monthlyBudget ? "monthly" : "range"] : []
        } : 
        {
            question : childQuestion.text,
            selectedOptions : childQuestion.options?.filter((opt) => opt.selected).map((opt) => opt.text.replace(/^[^a-zA-Z0-9]+/, '')
        ) || [],
            ...(childQuestion.inputCondition ? {inputValue : childQuestion.inputValue} : {})
        }
    })
}