import {
  BUDGET_QUIZ_INTERFACE,
  CUSTOMER_FORM_QUIZ_INTERFACE,
  DEFAULT_QUIZ_INTERFACE,
  MAKE_MODEL_SELECTION_QUIZ_INTERFACE,
  INVENTORY_SEARCH_QUIZ_INTERFACE,
  CONDITIONAL_INPUT_QUIZ_INTERFACE,
  TEST_DRIVE_APPROVAL_QUIZ_INTERFACE
} from "../../constants/quizInterfaces";
import { ELECTRIC_INCENTIVE_QUIZ_FACT, FEATURE_QUIZ_FACT } from './quizFacts'
import { minimumOptionsSelected, isAnsweredWithBudget, isAnsweredWithMakeModel, isAnsweredWithUserInfo, isAnsweredWithInput, isAnsweredWithTestDriveApproval } from "../../utils/quiz-selection-count";
import { allChildrenAnswered, oneChildrenAnswered } from "../../utils/quiz-view-tracker";
import { BUDGET_QUESTION_ID, FEATURE_QUESTION_ID, IK_IDK_QUESTION_ID, LIFESTYLE_QUESTION_ID, VEHICLE_POWER_QUESTION_ID, VEHICLE_TYPE_QUESTION_ID, NEW_USED_QUESTION_ID, CUSTOMER_INFO_QUESTION_ID, TEST_DRIVE_APPROVAL_QUESTION_ID } from "../../constants/questionIds";
import { collectResponsesAggregated, collectResponsesSeparate } from "../../utils/quiz-response-controller";
import { submitTestDriveApproval } from "../../utils/quiz-submit-utils";
import { CCF_ID } from "../../constants/dealershipIds";


export const CCFQuizQuestions = [
  {
    viewText: "Let's start - where are you in your car buying journey?",
    viewSubtext: "",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return true},
    visitedOnce : false,
    automaticForward : false,
    responses : collectResponsesSeparate,
    children: [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: INVENTORY_SEARCH_QUIZ_INTERFACE,
        optionCardsClassname: "quiz__options_cards two_wider_cards",
        optionStyle: "big-image",
        inputCondition: function() {  return this[0].children[0].options[0].selected === true },
        inputLabel: "Vehicle (Year, Make and Model)",
        inputValue: "",
        inputFromInventory : false,
        isAnswered : isAnsweredWithInput,
        childQuestionId: IK_IDK_QUESTION_ID,
        options: [
          {
            text: "I know what I want",
            subtext: "",
            greyicon: "ik.svg",
            selected: false,
          },
          {
            text: "I don’t know just yet",
            subtext: "",
            greyicon: "idk.svg",
            selected: false,
          },
        ],
      },
    ],
  },
  {
    viewText: "Why are you looking for a new car?",
    viewSubtext: "",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : true,
    responses : collectResponsesSeparate,
    children: [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "My current car is aging.",
            subtext: "",
            greyicon: "quiz-clock-grey.svg",
            selected: false,
          },
          {
            text: "I need more space for my stuff.",
            subtext: "",
            greyicon: "quiz-upsize-grey.svg",
            selected: false,
          },
          {
            text: "New member in the family.",
            subtext: "",
            greyicon: "quiz-baby-grey.svg",
            selected: false,
          },
          {
            text: "Getting my first car!",
            subtext: "",
            greyicon: "quiz-firstcar-grey.svg",
            selected: false,
          },
          {
            text: "Treating Myself!",
            subtext: "",
            greyicon: "quiz-gift-grey.svg",
            selected: false,
          },
          {
            text: "Downsizing",
            subtext: "",
            greyicon: "quiz-downsize-grey.svg",
            selected: false,
          },
          {
            text: "Other",
            subtext: "",
            greyicon: "",
            selected: false,
          },
        ],
      },
    ],
  },
  {
    viewText: "What type of vehicle are you looking for?",
    viewSubtext: "Select one",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
    (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : true,
    responses: collectResponsesSeparate,
    children: [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        childQuestionId: VEHICLE_TYPE_QUESTION_ID,
        options: [
          {
            text: "Car",
            subtext:
              "A sleek looking car, perfect for city life and weekend escapes",
            greyicon: "quiz-car-grey.svg",
            selected: false,
          },
          {
            text: "Small SUV",
            subtext:
              "A 5-seater with just enough trunk space for your everyday needs",
            greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Medium SUV",
            subtext: "A slightly larger 5-seater with more trunk space",
            greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Large SUV",
            subtext: "The roomiest 2 row or 3 row SUVs for larger families",
            greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Minivan",
            subtext:
              "A spacious 7-seater, ideal for family adventures and daily errands",
            greyicon: "quiz-minivan-grey.svg",
            selected: false,
          },
          {
            text: "Truck",
            subtext:
              "A robust 5-seater, ideal for outdoor excursions and heavy loads.",
            greyicon: "quiz-truck.svg",
            selected: false,
          },
        ],
      },
    ],
  },
  {
    viewText: "How do you want your vehicle powered?",
    viewSubtext: "Select your most preferred power type",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : false,
    responses : collectResponsesSeparate,
    quizFact: ELECTRIC_INCENTIVE_QUIZ_FACT,
    children :  [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection: 2},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "three_cards quiz__options_cards",
        isAnswered : minimumOptionsSelected,
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        childQuestionId: VEHICLE_POWER_QUESTION_ID,
        options: [
          {
            text: "Gasoline",
            subtext: "",
            greyicon: "quiz-gas-grey.svg",
            selected: false,
          },
          {
            text: "Hybrid",
            subtext: "",
            greyicon: "quiz-hybrid-grey.svg",
            selected: false,
          },
          {
            text: "Plug-in Hybrid",
            subtext: "",
            greyicon: "quiz-plugin-grey.svg",
            selected: false,
          },
          {
            text: "Electric",
            subtext: "",
            greyicon: "quiz-ev-grey.svg",
            selected: false,
          },
          {
            text: "Diesel",
            subtext: "",
            greyicon: "quiz-diesel-grey.svg",
            selected: false,
          },
        ],
      },
    ]
  },
  {
    viewText: "EV Additional Questions",
    viewSubtext: "",
    isAnswered : allChildrenAnswered,
    //selected Electric and i don't know
    visibilityCondition: function() {return this[3].children[0].options[3].selected === true && this[0].children[0].options[1].selected == true},
    visitedOnce : false,
    automaticForward : true,
    responses : collectResponsesSeparate,
    children: [
      {
        text: "Have you owned an EV before?",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "two_cards narrow_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "Yes",
            subtext: "",
            greyicon: "",
            selected: false,
          },
          {
            text: "No",
            subtext: "",
            greyicon: "",
            selected: false,
          },
        ],
      },
      {
        text: "Have you ever tried the One-Pedal or Roll Assist (Tesla) driving feature in an Electric Vehicle?",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "two_cards narrow_cards quiz__options_cards",
        optionStyle: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "Yes",
            subtext: "",
            greyicon: "",
            selected: false,
          },
          {
            text: "No",
            subtext: "",
            greyicon: "",
            selected: false,
          },
        ],
      },
    ],
  },
  {
    viewText: "What's your lifestyle?",
    viewSubtext: "Let us find a car that best matches your day to day needs and aligns with what’s important to you.",
    isAnswered : oneChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : false,
    responses : collectResponsesAggregated,
    questionId: LIFESTYLE_QUESTION_ID,
    children :  [
      {
        text: "Colors",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 0, maximumSelection : 8},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "feature-card",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "⚫ Black",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "⚪ White",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🩶 Grey",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🔵 Blue",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🔴 Red",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🟢 Green",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🟡 Yellow",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "Other Colour",
            subtext: "",
            selected: false,
            greyicon: "",
          },
        ],
      },
      {
        text: "Family",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 0, maximumSelection : 8},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "feature-card",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "👨🏻 Tall Family Members",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🐶 Large Dog",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🛞 Multiple Main Drivers",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "👶🏻 1-2 Kids",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "👶🏻 3-4 Kids",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "👶🏻 5+ Kids",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "👵🏻 Older Family Members",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🆕 New Driver",
            subtext: "",
            selected: false,
            greyicon: "",
          },
        ],
      },
      {
        text: "Outdoors and Equipment",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 0, maximumSelection : 9},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "feature-card",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "💺 Car Seat(s)",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "⛳ Golfing",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "⛷️ Skiing/Snowboarding",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🚴🏻 Biking",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "😴 Car Sleeping",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🏕️ Camping",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🛶 Canoe/Kayak",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "⚽ Sports Practices",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🏊 Water Sports",
            subtext: "",
            selected: false,
            greyicon: "",
          },
        ],
      },
      {
        text: "Towing",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 0, maximumSelection : 4},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "feature-card",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "Trailer",
            subtext: "",
            selected: false,
            greyicon: "van.png",
          },
          {
            text: "🛥️ Boat",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🏕️ Camper",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🚧 Construction",
            subtext: "",
            selected: false,
            greyicon: "",
          },
        ],
      },
      {
        text: "Driving Geography",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 0, maximumSelection : 6},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "feature-card",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "Off Roading",
            subtext: "",
            selected: false,
            greyicon: "jeep.png",
          },
          {
            text: "❄️ Snow",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "⛰️ Steep/Hilly Roads",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🏎️ Sporty Driving",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🏙️ Lots of City/Traffic",
            subtext: "",
            selected: false,
            greyicon: "",
          },
          {
            text: "🛣️ Lots of Highway Driving",
            subtext: "",
            selected: false,
            greyicon: "",
          },
        ],
      },
    ],
  },
  { 
    viewText: "What are some of your must-have features?",
    viewSubtext: "Select any that are important to you",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : false,
    responses : function() {
      const featureOptions = {};
      
        this.children[0].options.forEach((option) => {
          if (option.field) {
            featureOptions[option.field] = option.selected;
          }
        });
        return {
          question: this.viewText,
          selectedOptions: [featureOptions],
        };
    },
    quizFact: FEATURE_QUIZ_FACT,
    children: [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 8},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "three_cards quiz__options_cards",
        optionStyle: "feature",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        childQuestionId: FEATURE_QUESTION_ID,
        options: [
          {
            text: "Heated Seats",
            subtext: "",
            greyicon: "feature-heated-seats-grey.svg",
            selected: false,
            field: "heatedSeats",
          },
          {
            text: "Heated Steering Wheel",
            subtext: "",
            greyicon: "feature-heated-steering-wheel-grey.svg",
            selected: false,
            field: "heatedSteeringWheel",
          },
          {
            text: "Keyless Entry & Push Start",
            subtext: "",
            greyicon: "feature-push-start-grey.svg",
            selected: false,
            field: "keylessEntryAndPushStart",
          },
          {
            text: "Memory Seat",
            subtext: "",
            greyicon: "feature-seat-memory-grey.svg",
            selected: false,
            field: "seatMemory",
          },
          {
            text: "Power Seats",
            subtext: "",
            greyicon: "feature-power-seats-grey.svg",
            selected: false,
            field: "powerSeats",
          },
          {
            text: "Apple CarPlay/Android Auto",
            subtext: "",
            greyicon: "feature-carplay-grey.svg",
            selected: false,
            field: "appleCarPlayAndroidAuto",
          },
          {
            text: "Backup Camera",
            subtext: "",
            greyicon: "feature-sunroof-grey.svg",
            selected: false,
            field: "rearCamera",
          },
          {
            text: "Power Liftgate",
            subtext: "",
            greyicon: "feature-power-liftgate-grey.svg",
            selected: false,
            field: "powerLiftgate",
          },
        ],
      },
    ],
  },
  {
    viewText: "What’s your ideal budget?",
    viewSubtext: "budget",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : false,
    responses : function() {
        return { question: this.viewText,
                 selectedOptions: [...this.children[0].currSelected, this.children[0].monthlyBudget ? "monthly" : "range"]};
    },
    children: [
      {
        text: "",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        minBudget: "",
        maxBudget: "",
        monthlyBudget: "",
        quizInterface: BUDGET_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered: isAnsweredWithBudget,
        childQuestionId: BUDGET_QUESTION_ID,
      },
    ],
  },
  {
    viewText: "Additional Questions",
    viewSubtext: "",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,
    automaticForward : false,
    responses : collectResponsesSeparate,
    children :  [
      {
        text: "New or Used car?",
        subtext: "",
        selectionRange: {minimumSelection : 1, maximumSelection : 2},
        currSelected: [],
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "two_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered: minimumOptionsSelected,
        childQuestionId: NEW_USED_QUESTION_ID,
        options: [
          {
            text: "New",
            subtext: "",
            greyicon: "quiz-new.svg",
            selected: false,
          },
          {
            text: "Used",
            subtext: "",
            greyicon: "quiz-old.svg",
            selected: false,
          },
        ],
      },
      {
        text: "Would you like to Trade in your car?",
        subtext: "",
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        currSelected: [],
        optionCardsClassname: "three_cards quiz__options_cards",
        quizInterface: CONDITIONAL_INPUT_QUIZ_INTERFACE,
        optionStyle: "",
        inputCondition: function() {return this[8].children[1].options[0].selected === true || this[8].children[1].options[2].selected === true },
        inputLabel: "Vehicle (Year Make Model)",
        inputValue: "",
        isAnswered : isAnsweredWithInput,
        options: [
          {
            text: "Yes",
            subtext: "",
            greyicon: "Quiz-Correct.svg",
            selected: false,
          },
          {
            text: "No",
            subtext: "",
            greyicon: "Quiz-Wrong.svg",
            selected: false,
          },
          {
            text: "Haven’t decided",
            subtext: "",
            greyicon: "Quiz-Unsure.svg",
            selected: false,
          },
        ],
      },
      {
    
        text: "Are you interested in buying Cash or Financing or Leasing?",
        subtext: "",
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        currSelected: [],
        optionCardsClassname: "three_cards quiz__options_cards",
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        options: [
          {
            text: "Cash",
            subtext: "I’d like to own my car and pay for it in full with no monthly payments or interest",
            greyicon: "Quiz-Cash.svg",
            selected: false,
          },
          {
            text: "Finance",
            subtext: "I’d like to own my car and spread out the cost in monthly payments.",
            greyicon: "Quiz-Finance.svg",
            selected: false,
          },
          {
            text: "Lease",
            subtext: "I’d like lower monthly payments without the long-term commitment of ownership.",
            greyicon: "Quiz-Lease.svg",
            selected: false,
          },
        ],
      },
    ],
  },
  {
    viewText: "What other cars are you exploring?",
    viewSubtext:
      "Mae’s concierge can build you an unbiased comparison chart to make clear and fast decisions.",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return this[0].children[0].options[1].selected === true || 
      (this[0].children[0].options[0].selected === true && !this[0].children[0].inputFromInventory)},
    visitedOnce : false,automaticForward : false,
    responses : function() {
      const allFilledCars = this.children[0].currSelected.filter(
        (makeAndModelObj) => {
          return makeAndModelObj.make && makeAndModelObj.model;
        }
      );
      return {
        question: this.viewText,
        selectedOptions: allFilledCars,
      }
    },
    children :  [
      {
        text: "",
        subtext: "",
        selectionRange: {minimumSelection : 1, maximumSelection : 5},
        currSelected: [{ make: "", model: "" }],
        noOfCarsAdded: 0,
        quizInterface: MAKE_MODEL_SELECTION_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : isAnsweredWithMakeModel
      },
    ],
  },
  {
    viewText: "To complete your personalized check-in, please provide your contact details.",
    viewSubtext: "",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return true},
    visitedOnce : false,
    automaticForward : false,
    responses : function() {
      return {
        question : this.viewText,
        selectedOptions : [{
          firstName: this.children[0].firstName,
          lastName: this.children[0].lastName,
          phoneNo: this.children[0].phoneNo,
          email: this.children[0].email,
          preferredMethodOfContact: this.children[0].preferredMethodOfContact,
          postalCode: this.children[0].postalCode
        }]
      }
    },
    children: [
      {
        text: "",
        subtext: "",
        selectionRange: {minimumSelection : 4, maximumSelection : 4},
        currSelected: [],
        firstName: "",
        lastName: "",
        phoneNo: "",
        postalCode: "",
        hasPostalCode: false,
        email: "Not Mentioned",
        preferredMethodOfContact: "email",
        salesPersonInfo: "",
        quizInterface: CUSTOMER_FORM_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : isAnsweredWithUserInfo,
        childQuestionId: CUSTOMER_INFO_QUESTION_ID
      },
    ],
  },
  {
    viewText: "Test Drive and Pre-Approval",
    viewSubtext: "",
    header : "Test Drive",
    isAnswered : allChildrenAnswered,
    visibilityCondition: function() {return window.location.pathname === `${CCF_ID}/testdrive`},
    visitedOnce : false,
    automaticForward : false,
    submit : submitTestDriveApproval,
    responses : function() {
      return {
        testDriveApproval : this.children[0].testDriveApproval,
        financePreApproval : this.children[0].financePreApproval,
        dateOfBirth: this.children[0].dateOfBirth,
        employmentStatus: this.children[0].employmentStatus,
        sinNumber: this.children[0].sinNumber,
        experience: this.children[0].experience,
        signature : this.children[0].signature,
      }
    },
    children: [
      {
        text: "",
        subtext: "",
        selectionRange: {minimumSelection : 4, maximumSelection : 4},
        currSelected: [],
        dateOfBirth : "",
        employmentStatus : "",
        sinNumber : "",
        experience : "",
        signature : "",
        testDriveApproval : false,
        financePreApproval : false,
        quizInterface: TEST_DRIVE_APPROVAL_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : isAnsweredWithTestDriveApproval,
        childQuestionId: TEST_DRIVE_APPROVAL_QUESTION_ID
      },
    ],
  },
];
