export const DEFAULT_QUIZ_INTERFACE = "DEFAULT_QUIZ_INTERFACE";
export const BUDGET_QUIZ_INTERFACE = "BUDGET_QUIZ_INTERFACE";
export const MAKE_MODEL_SELECTION_QUIZ_INTERFACE = "MAKE_MODEL_SELECTION_QUIZ_INTERFACE";
export const CUSTOMER_FORM_QUIZ_INTERFACE = "CUSTOMER_FORM_QUIZ_INTERFACE";
export const RANKING_QUIZ_INTERFACE = "RANKING_QUIZ_INTERFACE";
export const MULTIPLE_CATEGORY_QUIZ_INTERFACE = "MULTIPLE_CATEGORY_QUIZ_INTERFACE";
export const MULTIPLE_QUESTION_QUIZ_INTERFACE = "MULTIPLE_QUESTION_QUIZ_INTERFACE";
export const INVISIBLE_QUIZ_INTERFACE = "INVISIBLE_QUIZ_INTERFACE";
export const CONDITIONAL_INPUT_QUIZ_INTERFACE = "CONDITIONAL_INPUT_QUIZ_INTERFACE";
export const INVENTORY_SEARCH_QUIZ_INTERFACE = "INVENTORY_SEARCH_QUIZ_INTERFACE";
export const TEST_DRIVE_APPROVAL_QUIZ_INTERFACE = "TEST_DRIVE_APPROVAL_QUIZ_INTERFACE"