import {ReactComponent as MaeCoreLogoBlack} from '../../assets/logos/MaeCoreLogoBlack.svg'
import { CCF_ID } from '../../constants/dealershipIds';
import { MONTHLY_BUDGET_VALUES } from "../../data/budgetData";
import { ANRQuizIndexMapping, CCFQuizIndexMappingV0, CCFQuizIndexMappingV1 } from '../../data/dealershipQuiz/quizIndexMapping';

export default function UserProfile ({ dealershipId, user, dealershipImgUrl, carMatches, salespersonName }) {

  //backward compatibility with the old quiz for ccf
  let quizIndexMapping = dealershipId === CCF_ID ? user.profile.quiz.length > 14 ? CCFQuizIndexMappingV0 : CCFQuizIndexMappingV1 : ANRQuizIndexMapping; 
  let ikInputValue = dealershipId === CCF_ID && user.profile.quiz.length > 14 ? 
  user.profile.quiz[quizIndexMapping.IK_VEHICLE_INPUT_INDEX].selectedOptions.join(', ') : user.profile.quiz[quizIndexMapping.IKIDK_INDEX].inputValue;

  console.log(user.profile.quiz[quizIndexMapping.PICK_CARS_INDEX].selectedOptions)

  let budget = user.profile.quiz[quizIndexMapping.BUDGET_INDEX].selectedOptions
  let monthlyPayment = false
  if(budget[1] == "monthly"){
    monthlyPayment = MONTHLY_BUDGET_VALUES.find(item => item.value === budget[0]);
  }

  const signatureTerms = `*By clicking ‘Submit’ and signing electronically, you consent to the use of your electronic signature as legally binding under applicable electronic signature laws. You also authorize Applewood Nissan Richmond to process your information for test drive scheduling and/or pre-approval purposes.`
  const financePreApprovalDisclaimer =`Portions of the above information will be submitted for the purposes of obtaining credit. The undersigned authorizes any of the Applewood Group Dealerships to make such inquiries as are necessary to obtain credit information and authorize banks, financial institutions, insurers, and credit references to release information regarding their accounts.
                                You acknowledge that, for the initial application, we may obtain two credit bureaus on each Applicant and Co-Applicant.`
  const testDriveApprovalDisclaimer = `This test drive agreement (the "Agreement”) is made by and between CUSTOMER . (the "Customer”) and APPLEWOOD NISSAN RICHMOND (the Dealer”) to set forth the terms under which the Customer may evaluate the vehicle with stock and tag identification numbers as listed above (the "Vehicle”). 
                             Customer hereby agrees to the following:
                            1.) Customer shall only drive the Vehicle from the Start Date and Time until the End Date and Time as defined above.
                            2.) Customer shall be responsible for any and all liabilities (civil, criminal or otherwise) incurred while driving the Vehicle.
                            3.) Customer shall not use the Vehicle for any purpose deemed illicit or illegal, or for any purpose which is otherwise in violation of federal, state or local laws.
                            This Agreement shall in no way imply the transfer of ownership of the Vehicle from Dealer to Customer. 
                            Failure to comply with the terms of this Agreement, including failure to return the Vehicle at or before the Test Drive End Time on the Test Drive End Date, may result in criminal and/or civil proceedings being brought against Customer.
                            I accept full responsibility while using this vehicle. | will provide insurance at or above dealer requirements or state statutes covering liability, physical damage, and bodily injury for myself or any passenger (other than the salesperson) that may arise from the use, operation, maintenance or possession of the vehicle.`


  return (
  <div style={{ maxWidth: '600px', display:"flex",flexDirection:"column",gap:"0.5rem"}} className="printable">
    <img src={dealershipImgUrl} alt = "" style={{width:"180px",marginLeft:"1rem"}}/>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:"center"}}>
      <h1 style={{margin:"0.2rem 1rem"}}> {user.firstName} {user.lastName}</h1>
      <h5 style={{marginLeft:"1rem"}}> <strong>Salesperson: {salespersonName}</strong> </h5>
    </div>
    

    <h4 style={{marginLeft:"1rem"}}>{user.phoneNo}, {user.email} (Preferred: {user.preferredMethodOfContact})</h4>

    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
      <div style={{ flex: 1, background: 'rgb(255,204,79,0.5)', padding: '1rem', borderRadius: '10px', border: '2px solid rgb(255,204,79,0.5)' }}>
        <h3>Car Info</h3>
        {ikInputValue? <p style={{margin:"0.2rem 0rem"}}><strong>Came in for:</strong> {ikInputValue}</p> : ""}
        <p style={{margin:"0.2rem 0rem"}}><strong>Looking for:</strong> {user.profile.quiz[quizIndexMapping.NEW_USED_INDEX].selectedOptions.join(' & ')} {user.profile.quiz[quizIndexMapping.VEHICLE_TYPE_INDEX].selectedOptions.join(', ')}</p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Power:</strong> {Array.from(new Set(user.profile.quiz[quizIndexMapping.VEHICLE_ENGINE_INDEX].selectedOptions)).join(', ')}</p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Why?</strong> {user.profile.quiz[quizIndexMapping.REASON_NEW_VEHICLE_INDEX].selectedOptions}</p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Lifestyle & Must Haves:</strong> {user.profile.quiz[quizIndexMapping.LIFESTYLE_INDEX].selectedOptions.join(', ')} {user.userSelectedFeatures.join(', ')}</p>
        {user.profile.quiz[quizIndexMapping.PICK_CARS_INDEX].selectedOptions.length > 0 ? <p style={{margin:"0.2rem 0rem"}}><strong>Also considering:</strong> {user.profile.quiz[quizIndexMapping.PICK_CARS_INDEX].selectedOptions.map(({make,model}) => {return `${make} ${model}`}).join(', ')}</p>:""}
      </div>
      
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ background: 'rgb(255,204,79,0.5)', padding: '1rem', borderRadius: '10px', border: '2px solid rgb(255,204,79,0.5)' }}>
          <h3>Purchase Info</h3>
          <p style={{margin:"0.2rem 0rem"}}><strong>Trade In?</strong> {user.profile.quiz[quizIndexMapping.TRADE_IN_INDEX]?.selectedOptions}, {user.profile.quiz[quizIndexMapping.TRADE_IN_INDEX]?.inputValue}</p>
          {quizIndexMapping.TRADE_IN_PAY_OFF_INDEX? <p style={{margin:"0.2rem 0rem"}}><strong>Paid off?</strong> {user.profile.quiz[quizIndexMapping.TRADE_IN_PAY_OFF_INDEX]?.selectedOptions}, left: ${user.profile.quiz[quizIndexMapping.TRADE_IN_PAY_OFF_INDEX]?.inputValue}</p>: ""}
          <p style={{margin:"0.2rem 0rem"}}><strong>Downpayment:</strong> {user.profile.quiz[quizIndexMapping.PAYMENT_MODE_INDEX]?.inputValue}</p>
          <p style={{margin:"0.2rem 0rem"}}><strong>Budget:</strong> {user.profile.quiz[quizIndexMapping.BUDGET_INDEX].selectedOptions[0]} {monthlyPayment? `(${monthlyPayment.text} monthly)`:""}</p>
          <p style={{margin:"0.2rem 0rem"}}><strong>Financing Option:</strong> {user.profile.quiz[quizIndexMapping.PAYMENT_MODE_INDEX].selectedOptions.join(', ')}</p>
        </div>
      </div>
    </div>

    <div style={{ background: 'rgb(255,204,79,0.5)', padding: '1rem', borderRadius: '10px', border: '2px solid rgb(255,204,79,0.5)' }}>
  <p>Number of Matches: <strong>{carMatches.length}</strong></p>
  
  {
    carMatches.length === 0 ? null : <p>Matched New Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle["website_condition"] === "New"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.website_stockNumber})`))
      .join(", ")}</strong></p>
  }

  <br></br>

  {
    carMatches.length === 0 ? null : <p>Matched Used Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle["website_condition"] === "Used"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.website_stockNumber})`))
      .join(", ")}</strong></p>
  } 
  </div>

  
  <div style={{ pageBreakAfter: 'always' }}></div>
  {user?.testDriveFinancePreApprovalInfo ? <div style={{ background: 'rgb(255,204,79,0.5)', padding: '1rem', borderRadius: '10px', border: '2px solid rgb(255,204,79,0.5)' }}>
        <h3>Test Drive and Finance Pre-approval</h3>
        <p style={{margin:"0.2rem 0rem"}}><strong>Test Drive Approval: {user?.testDriveFinancePreApprovalInfo?.testDriveApproval ? "✔" : "X"}</strong> </p>
        <p style={{fontSize:"0.5rem"}}>{testDriveApprovalDisclaimer}</p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Finance Pre-Approval: {user?.testDriveFinancePreApprovalInfo?.financePreApproval ? "✔" : "X"}</strong> </p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Birthday: {user?.testDriveFinancePreApprovalInfo?.dateOfBirth}</strong> </p>
        <p style={{margin:"0.2rem 0rem"}}><strong>SIN: {user?.testDriveFinancePreApprovalInfo?.sinNumber}</strong> </p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Employment Status: {user?.testDriveFinancePreApprovalInfo?.employmentStatus}</strong> </p>
        <p style={{margin:"0.2rem 0rem"}}><strong>Experience: {user?.testDriveFinancePreApprovalInfo?.experience}</strong> </p>
        <p style={{fontSize:"0.5rem"}}>{financePreApprovalDisclaimer}</p>
        {user?.testDriveFinancePreApprovalInfo?.signature? <img style={{margin:"0.2rem 0rem"}} src={user?.testDriveFinancePreApprovalInfo?.signature} alt="Signature" className="w-auto h-32" /> : ""}
        <p style={{fontSize:"0.5rem"}}>{signatureTerms}</p>
    </div> : ""}

  </div> )
};