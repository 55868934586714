import { ANR_ID, CCF_ID } from "../constants/dealershipIds";

export const DEALERSHIP_CONFIG = {
    [CCF_ID]:{
        name: "Cam Clark Ford",
        imgUrl: "https://ddztmb1ahc6o7.cloudfront.net/camclarkfordrichmond/wp-content/uploads/2024/04/12160321/Cam-Clark-Ford-PNG.png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false,
        salesPeople: {
            0: {name:"", email:""},
            1: {name:"Dennis Liu", email:"dliu@camclarkford.com"},
            2: {name:"Chris Yu", email:"cyu@camclarkford.com"},
            3: {name:"Daniel Chiu", email:"dchiu@camclarkford.com"},
            4: {name:"Jimmy Fu", email:"jfu@camclarkford.com"},
            5: {name:"Tatiana Lopez", email:"tlopez@camclarkford.com"},
            6: {name:"Prab Bansal", email:"pbansal@camclarkford.com"},
            7: {name:"Justin Liang", email:"jliang@camclarkford.com"},
            8: {name:"Jeff Ng", email:"jng@camclarkford.com"},
            9: {name:"Emre Celik", email:"house@camclarkfordrichmond.com"}
        },        
        // salesPeople: {
        //     0: {name:"", email:""},
        //     1: {name:"sp1 Dennis Liu", email:"sp1@makingautoeasy.com"},
        //     2: {name:"sp2 Chris Yu", email:"sp2@makingautoeasy.com"},
        //     3: {name:"sp3 Daniel Chiu", email:"sp3@makingautoeasy.com"},
        //     4: {name:"sp4 Jimmy Fu", email:"sp4@makingautoeasy.com"},
        //     5: {name:"sp5 Tatiana Lopez", email:"sp5@makingautoeasy.com"},
        //     6: {name:"sp6 Prab Bansal", email:"sp6@makingautoeasy.com"},
        //     7: {name:"sp7 Justin Liang", email:"sp7@makingautoeasy.com"},
        //     8: {name:"sp8 Jeff Ng", email:"sp8@makingautoeasy.com"},
        //     9: {name:"sp9 Emre Celik", email:"sp9@makingautoeasy.com"}
        // }
        
    },
    "trial":{
        name: "Your Dealership Name",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(2)+(1).png",
        linkMatchesToVDP: false,
        defaultInventoryImage: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(3).png"
    },
    [ANR_ID]:{
        name: "Applewood Nissan Richmond",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(5)+(1).png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false,
        salesPeople: {
            0: {name:"",email:""},
            1: { name: "Elijah Pikientio", email: "sp1@makingautoeasy.com" },
            2: { name: "Kayden Kallu", email: "sp5@makingautoeasy.com" },
            3: { name: "Keanu Anand", email: "sp8@makingautoeasy.com" },
            4: { name: "Janet Yu", email: "sp9@makingautoeasy.com" },
            5: { name: "Ricky Au", email: "sp10@makingautoeasy.com" },
            6: { name: "Niki Levai", email: "sp11@makingautoeasy.com" },
            7: { name: "Miguel De Guzman", email: "sp13@makingautoeasy.com" },
            8: { name: "Sam Yahyavi", email: "sp14@makingautoeasy.com" },
            9: { name: "Charles Tu", email: "sp2@makingautoeasy.com" },
            // 14: { name: "Vivian Liu", email: "sp2@makingautoeasy.com" },
        }        
    },
}