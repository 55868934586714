import "./DashboardHome.scss";
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import dashboardImage from "../../assets/images/laptop-compare.png";
import stadiumPitchImage from "../../assets/images/applewood stadium pitch.png";
import { Link } from "react-router-dom";
import { useDealershipContext } from "../../contexts/dealershipContext";
import {ReactComponent as MaeCoreLogoYellow} from '../../assets/logos/Mae-Minimized-Logo-Yellow.svg';
import { ANR_ID } from "../../constants/dealershipIds";

function DashboardHome() {
  const { dealershipName, dealershipImgUrl, dealershipId } = useDealershipContext();

  return (
    <div className="landing_page">
      <div className="product_info">
      <img className="product_info__dealership_logo" src={dealershipImgUrl} alt="" />
        <h1 className="product_info__main_text" style={{fontFamily: dealershipId == ANR_ID? "Montserrat Medium": "Poppins Medium"}}>Your Customized Car Journey Starts Here</h1>
        {dealershipId == ANR_ID? "" :
        <div className="product_info__creator_section">
          <div className="product_info__text" style={{fontWeight : "400"}}>Powered By</div>
          <div className="product_info__logo">
             <MaeCoreLogoYellow />
          </div>
        </div>}
        {dealershipId == ANR_ID? "" :
        <p className="product_info__text product_info__text--margins"  >
        Welcome to {dealershipName}, we’re excited to be providing you with a 
        customized and seamless car shopping journey. Please take a moment to 
        check-in on this iPad so our team can personalize your experience and 
        help you discover the best of what our dealership has to offer. 
        </p>}
        <br></br>
        <div className="product_info__button">
          <Link to={`/${dealershipId}/matchmaker-quiz`}>
          {dealershipId == ANR_ID?
            <ButtonGradient text="Start Check-in" style="red"/>
            :
            <ButtonGradient text="Start Check-in" />
          }
          </Link>
        </div>
      </div>
      <div className={`landing_page__filler ${dealershipId == ANR_ID ? "red-gradient" : ""}`}>
        <img className="landing_page__filler--image"
        src={dealershipId == ANR_ID? stadiumPitchImage: dashboardImage}
        alt=""
        style = {{marginRight: dealershipId == ANR_ID? "7em": "-3em"}}
         />
      </div>
    </div>
  );
}

export default DashboardHome;
