import {
  BUDGET_QUIZ_INTERFACE,
  DEFAULT_QUIZ_INTERFACE,
  INVENTORY_SEARCH_QUIZ_INTERFACE
} from "../../constants/quizInterfaces";
import { minimumOptionsSelected, isAnsweredWithBudget, isAnsweredWithInput } from "../../utils/quiz-selection-count";
import { oneChildrenAnswered } from "../../utils/quiz-view-tracker";
import { BUDGET_QUESTION_ID, VEHICLE_POWER_QUESTION_ID, VEHICLE_TYPE_QUESTION_ID, NEW_USED_QUESTION_ID, IK_IDK_QUESTION_ID, FEATURE_QUESTION_ID } from "../../constants/questionIds";
import { updatedProfileResponses } from "../../utils/quiz-response-controller";
import { submitUserUpdatedProfile } from "../../utils/quiz-submit-utils";
import { ANRProfileUpdateQuizIndexMapping, ANRQuizIndexMapping } from "./quizIndexMapping";
import { MAX_BUDGET_VALUES, MIN_BUDGET_VALUES } from "../budgetData";

export const ANRUpdateQuizQuestions = [
  {
    viewText: "Update Profile",
    viewSubtext: "Car Matches will be Updated accordingly",
    isAnswered : oneChildrenAnswered,
    visibilityCondition: function() {return true},
    visitedOnce : false,
    header : "Update your profile",
    automaticForward : false,
    responses : updatedProfileResponses,
    submit : submitUserUpdatedProfile,
    children: [
      // {
      //   text: "Let's start - where are you in your car buying journey?",
      //   subtext: "",
      //   currSelected: [],
      //   selectionRange: {minimumSelection : 1, maximumSelection : 1},
      //   quizInterface: INVENTORY_SEARCH_QUIZ_INTERFACE,
      //   optionCardsClassname: "quiz__options_cards two_wider_cards",
      //   optionStyle: "big-image",
      //   inputCondition: function() {  return this[0].children[ANRProfileUpdateQuizIndexMapping.IKIDK_INDEX].options[0].selected === true },
      //   inputLabel: "Vehicle (Year, Make and Model)",
      //   inputValue: "",
      //   inputFromInventory : false,
      //   isAnswered : isAnsweredWithInput,
      //   prefill : function(response, userCarMatches) {
      //     this.options.forEach((option,i) => {
      //       option.selected = response.selectedOptions.includes(option.text);
      //       if(response.selectedOptions.includes(option.text) && !this.currSelected.includes(i)) {
      //         this.currSelected.push(i);
      //       }
            
      //     });
      //     this.inputValue = response.inputValue;
      //     if(userCarMatches?.length > 0) {
      //       console.log(`${userCarMatches[0]?.year} ${userCarMatches[0]?.make} ${userCarMatches[0]?.model}${userCarMatches[0]?.trim ? ` ${userCarMatches[0]?.trim}` : ""}`)
      //       this.inputFromInventory = `${userCarMatches[0]?.year} ${userCarMatches[0]?.make} ${userCarMatches[0]?.model}${userCarMatches[0]?.trim ? ` ${userCarMatches[0]?.trim}` : ""}` === response.inputValue;
      //     }
      //   },
      //   childQuestionId: IK_IDK_QUESTION_ID,
      //   options: [
      //     {
      //       text: "I know what I want",
      //       // subtext: "",
      //       // greyicon: "ik.svg",
      //       selected: false,
      //     },
      //     {
      //       text: "I don’t know just yet",
      //       // subtext: "",
      //       // greyicon: "idk.svg",
      //       selected: false,
      //     },
      //   ],
      // },
      {
        text: "What type of vehicle are you looking for?",
        subtext: "Select one",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        prefill : function(response) {
          this.options.forEach((option,i) => {
            if(response.selectedOptions.includes(option.text) && !this.currSelected.includes(i) && this.selectionRange.maximumSelection > this.currSelected.length) {
              this.currSelected.push(i);
              option.selected = response.selectedOptions.includes(option.text);
            }
           
          });
          this.inputValue = response.inputValue;
        },
        childQuestionId: VEHICLE_TYPE_QUESTION_ID,
        options: [
          {
            text: "Car",
            // subtext:
            //   "A sleek looking car, perfect for city life and weekend escapes",
            // greyicon: "quiz-car-grey.svg",
            selected: false,
          },
          {
            text: "Small SUV",
            // subtext:
            //   "A 5-seater with just enough trunk space for your everyday needs",
            // greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Medium SUV",
            // subtext: "A slightly larger 5-seater with more trunk space",
            // greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Large SUV",
            // subtext: "The roomiest 2 row or 3 row SUVs for larger families",
            // greyicon: "quiz-suv-grey.svg",
            selected: false,
          },
          {
            text: "Minivan",
            // subtext:
            //   "A spacious 7-seater, ideal for family adventures and daily errands",
            // greyicon: "quiz-minivan-grey.svg",
            selected: false,
          },
          {
            text: "Truck",
            // subtext:
            //   "A robust 5-seater, ideal for outdoor excursions and heavy loads.",
            // greyicon: "quiz-truck.svg",
            selected: false,
          },
        ],
      },
      {
        text: "How do you want your vehicle powered?",
        subtext: "Select your most preferred power type",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection: 2},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "three_cards quiz__options_cards",
        isAnswered : minimumOptionsSelected,
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        prefill : function(response) {
          this.options.forEach((option,i) => {
            if(response.selectedOptions.includes(option.text) && !this.currSelected.includes(i) && this.selectionRange.maximumSelection > this.currSelected.length) {
              option.selected = response.selectedOptions.includes(option.text);
              this.currSelected.push(i);
            }
           
          });
          this.inputValue = response.inputValue;
        },
        childQuestionId: VEHICLE_POWER_QUESTION_ID,
        options: [
          {
            text: "Gasoline",
            // subtext: "",
            // greyicon: "quiz-gas-grey.svg",
            selected: false,
          },
          {
            text: "Hybrid",
            // subtext: "",
            // greyicon: "quiz-hybrid-grey.svg",
            selected: false,
          },
          {
            text: "Plug-in Hybrid",
            // subtext: "",
            // greyicon: "quiz-plugin-grey.svg",
            selected: false,
          },
          {
            text: "Electric",
            // subtext: "",
            // greyicon: "quiz-ev-grey.svg",
            selected: false,
          },
          {
            text: "Diesel",
            // subtext: "",
            // greyicon: "quiz-diesel-grey.svg",
            selected: false,
          },
        ],
      },
      {
        text: "What’s your ideal budget?",
        subtext: "",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 1},
        minBudget: "",
        maxBudget: "",
        monthlyBudget: "",
        quizInterface: BUDGET_QUIZ_INTERFACE,
        optionCardsClassname: "four_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered: isAnsweredWithBudget,
        prefill : function(response) {
          const budgetString = response.selectedOptions[0];
          const budgetType = response.selectedOptions[1];
          const [minBudget, maxBudget] = budgetString.split(" - ").map(value => parseInt(value.trim().replace(/[$,]/g,""),10));
          console.log(minBudget, maxBudget, "budgets")
          if(budgetType === "monthly") {
            this.monthlyBudget = budgetString
          } else {
            this.maxBudget = MAX_BUDGET_VALUES.find((budgetVal) => parseInt(budgetVal.trim().replace(/[$,]/g,""),10) >= maxBudget) || MAX_BUDGET_VALUES.at(-1);
            this.minBudget = MIN_BUDGET_VALUES.find((budgetVal) => parseInt(budgetVal.trim().replace(/[$,]/g,""),10) >= minBudget) || MIN_BUDGET_VALUES.at(-1);
          }
          console.log(this.minBudget, this.maxBudget);
        },
        childQuestionId: BUDGET_QUESTION_ID,
      },
      {
        text: "New or Used car?",
        subtext: "",
        selectionRange: {minimumSelection : 1, maximumSelection : 2},
        currSelected: [],
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "two_cards quiz__options_cards",
        optionStyle: "",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered: minimumOptionsSelected,
        prefill : function(response) {
          this.options.forEach((option,i) => {
            if(response.selectedOptions.includes(option.text) && !this.currSelected.includes(i) && this.selectionRange.maximumSelection > this.currSelected.length) {
              this.currSelected.push(i);
              option.selected = response.selectedOptions.includes(option.text);
            }
           
          });
          this.inputValue = response.inputValue;
        },
        childQuestionId: NEW_USED_QUESTION_ID,
        options: [
          {
            text: "New",
            // subtext: "",
            // greyicon: "quiz-new.svg",
            selected: false,
          },
          {
            text: "Used",
            // subtext: "",
            // greyicon: "quiz-old.svg",
            selected: false,
          },
        ],
      },
      {
        text: "What are some of your must-have features?",
        subtext: "Select any that are important to you",
        currSelected: [],
        selectionRange: {minimumSelection : 1, maximumSelection : 8},
        quizInterface: DEFAULT_QUIZ_INTERFACE,
        optionCardsClassname: "three_cards quiz__options_cards",
        visibilityCondition : function() {return false},
        optionStyle: "feature",
        inputCondition: null,
        inputLabel: "",
        inputValue: "",
        isAnswered : minimumOptionsSelected,
        childQuestionId: FEATURE_QUESTION_ID,
        options : []
    },
    ],
  },
];
